import { utils } from 'ethers';

export const formatNumberWithMaxDecimals = (
  value: any,
  maxDecimals?: number
): number => {
  const formattedNumber = Number(value).toFixed(maxDecimals ?? 2);
  return Number(formattedNumber.replace(/\.?0+$/, '')); // Removes trailing zeros
};

export const weiToDecimals = (value: number, decimals: number) => {
  return Number(value) / 10 ** decimals;
};

export const isAddressValid = (address: string): boolean => {
  if (!utils.isAddress(address)) {
    return false;
  }
  return true;
};

export const sliceTransactionHash = (transactionHash: string): string => {
  const prefix = transactionHash.slice(0, 4);
  const body = '...';
  const suffix = transactionHash.slice(-4);

  return `${prefix}${body}${suffix}`;
};

export const sliceAddress = (address: `0x${string}`, to?: number): string => {
  const prefix = address.slice(0, to ?? 4);
  const body = '...';
  const suffix = address.slice(-(to ?? 4));

  return `${prefix}${body}${suffix}`;
};

export function useGetRandomColor(): string {
  const colors = ['red', 'blue', 'green', 'yellow', 'purple', 'pink', 'orange'];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
}
