import {
  Button,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  HStack,
  Heading,
  Spacer,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  useGetUserPrincipalValue,
  useGetUserRewards,
  useGetUserStakingReward,
} from '../../hooks/StakingHooks';
import { CenterComponent } from '../../util/Ui';
import { useContractWrite } from 'wagmi';
import { supportedNetworkInfo } from '../../constants/SupportedNetworkInfo';
import { Polygon } from '@usedapp/core';

export function StakingInfoCard({
  userAddress,
}: {
  userAddress: `0x${string}` | undefined;
}) {
  const stakingReward = useGetUserStakingReward(userAddress);
  const userPrincipalValue = useGetUserPrincipalValue(userAddress);
  const userRewards = useGetUserRewards(userAddress);
  const currentNetwork = supportedNetworkInfo[Polygon?.chainId]

  const { data, isLoading, isSuccess, write } = useContractWrite({
    address: currentNetwork?.stakingContractAddress,
    abi: currentNetwork?.stakingContractInterface,
    functionName: 'claimStakingReward',
  })

  return (
    <CenterComponent>
      <VStack minW={250} maxW={300} w="full" spacing={5}>
        <VStack>
          <HStack w="full">
            {/* <Text fontWeight="bold">Staking ID</Text>
            <Spacer />
            <Heading size="md" color="orange.500">
              #{Number(stakingId)}
            </Heading> */}
          </HStack>
          <Divider></Divider>
        </VStack>

        <VStack spacing={1}>
          <Tag size="lg" colorScheme="twitter" borderRadius="xl">
            Value Staked
          </Tag>
          <Heading size="md">{userPrincipalValue}</Heading>
          <Heading size="sm" color="orange.500">
            BTCin
          </Heading>
        </VStack>
        <VStack>
          <Tag size="lg" colorScheme="twitter" borderRadius="xl">
            Reward Claimed
          </Tag>

          <Heading size="md">{userRewards?.stakingRewardClaimed}</Heading>
          <Heading size="sm" color="orange.500">
            BTCin
          </Heading>
        </VStack>
        <VStack>
          <Tag size="lg" colorScheme="twitter" borderRadius="xl">
            Pending Reward
          </Tag>
          <Heading size="md">{stakingReward}</Heading>
          <Heading size="sm" color="orange.500">
            BTCin
          </Heading>
          <Button
            borderRadius="xl"
            colorScheme="orange"
            bg="orange.500"
            _hover={{
              bg: 'orange.400',
            }}
            onClick={() => write?.()}
          >
            Claim Staking Reward
          </Button>
        </VStack>
      </VStack>
    </CenterComponent>
  );
}

// import React from 'react'

// export const StakingInfoCard = () => {
//   return (
//     <div>StakingInfoCard</div>
//   )
// }
