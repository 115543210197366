import StakingContractInterface from '../contracts/artifacts/contracts/StakingUpgradeable.sol/StakingUpgradeable.json';
import { erc20ABI } from 'wagmi';

export const AddressZero: `0x${string}` =
  '0x0000000000000000000000000000000000000000';
export const AddressDead: `0x${string}` =
  '0x000000000000000000000000000000000000dEaD';

export type ContractObject = {
  abi: any;
  polygonAddress: `0x${string}`;
  bscAddress: `0x${string}`;
  myveeAddress: `0x${string}`;
  bscTesntnetAddress: `0x${string}`;
};

export type TokenContractObject = {
  abi: any;
  polygonAddress: `0x${string}`;
  bscAddress: `0x${string}`;
  myveeAddress: `0x${string}`;
  bscTesntnetAddress: `0x${string}`;
};

export const StakingContractObject: ContractObject = {
  abi: StakingContractInterface?.abi,
  // polygonAddress: "0xa777b34bF9F9379E9E1Ec8694F75A713e011A345",
  // polygonAddress: "0x40C2bD3b45dcDee983818455796f2EA17e1A8456",
  polygonAddress: "0x1Dd6Ca23C7012502764a16D1a5a0bbFC75FF40E5",
  bscAddress: AddressZero,
  myveeAddress: AddressZero,
  bscTesntnetAddress: '0xEdCb6DEA81625a85ca04A66b331843347Db4CBe2',
};

export const USDT: TokenContractObject = {
  abi: erc20ABI,
  polygonAddress: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  bscAddress: '0x55d398326f99059fF775485246999027B3197955',
  myveeAddress: '0x3bcBA4C6223D98B9265601b6129A9436F810669B',
  bscTesntnetAddress: '0xe6ffee89beb3bee2785eE88deD4Da74F8a082A78',
};

export const BUSD: TokenContractObject = {
  abi: erc20ABI,
  polygonAddress: '0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39',
  bscAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  myveeAddress: '0xc84837B0b1Ea22A831Cf000aFB4E2D88BDE8c1E7',
  bscTesntnetAddress: '0x07fd92CDa316fEf52c7d4d76Fe21f658e46c211D',
};

export const BTCin: TokenContractObject = {
  abi: erc20ABI,
  polygonAddress: '0x32f81f5fa147027f990b6a35e14b41b5990fce1a',
  bscAddress: '0x32f81f5fa147027f990b6a35e14b41b5990fce1a',
  myveeAddress: '0x5A635b14a05003ac56D37546a2E8cfdBd21B8A90',
  bscTesntnetAddress: '0x07fd92CDa316fEf52c7d4d76Fe21f658e46c211D',
};
