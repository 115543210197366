import { useContractRead, useNetwork } from 'wagmi';
import {
  AddressZero,
  supportedNetworkInfo,
} from '../constants/SupportedNetworkInfo';
import { formatNumberWithMaxDecimals } from './UtilHooks';
import { BSCTestnet, useCall } from '@usedapp/core';
import { Contract } from 'ethers';

export const useContractCall = ({
  functionName,
  args,
}: {
  functionName: string;
  args?: any[];
}) => {
  const { chain } = useNetwork();
  const currentNetwork = supportedNetworkInfo[chain?.id ?? 137];

  const { data, isError, isLoading, error } = useContractRead({
    address: currentNetwork?.stakingContractAddress,
    abi: currentNetwork?.stakingContractInterface,
    functionName: functionName,
    args: args ?? [],
  });

  if (isError) {
    console.log('Referral Hook Error', error?.message);
    return undefined;
  }

  return data;
};

// export const useContractCall = ({
//   functionName,
//   args,
// }: {
//   functionName: string;
//   args?: any[];
// }) => {
//   const { chain } = useNetwork();
//   const currentNetwork = supportedNetworkInfo[chain?.id ?? BSCTestnet.chainId];

//   const data = useCall(
//     functionName && {
//       contract: new Contract(
//         `${currentNetwork?.stakingContractAddress}`,
//         currentNetwork?.stakingContractInterface
//       ),
//       method: functionName,
//       args: args ?? [],
//     }
//   );

//   if (data?.error) {
//     console.log('Referral Hook Error', data?.error?.message);
//     return undefined;
//   }

//   return data?.value;
// };

export const useGetUserBusiness = (userAddress: `0x${string}` | undefined) => {
  const value = useContractCall({
    functionName: 'getUserBusiness',
    args: [userAddress],
  });

  const valueObject = {
    selfBusiness: value
      ? Number(formatNumberWithMaxDecimals(Number(value[0]) / 10 ** 18, 3))
      : 0,
    directBusiness: value
      ? Number(formatNumberWithMaxDecimals(Number(value[1]) / 10 ** 18, 3))
      : 0,
    teamBusiness: value
      ? Number(formatNumberWithMaxDecimals(Number(value[2]) / 10 ** 18, 3))
      : 0,
    totalBusiness: value
      ? Number(formatNumberWithMaxDecimals(Number(value[3]) / 10 ** 18, 3))
      : 0,
  };

  console.log(value);

  return valueObject;
};

export const useGetUserRewards = (userAddress: `0x${string}` | undefined) => {
  const value = useContractCall({
    functionName: 'getUserRewards',
    args: [userAddress],
  });

  const valueObject = {
    stakingRewardClaimed: value
      ? formatNumberWithMaxDecimals(Number(value[0]) / 10 ** 18, 3)
      : 0,
    referralReward: value
      ? formatNumberWithMaxDecimals(Number(value[1]) / 10 ** 18, 3)
      : 0,
  };

  return valueObject;
};

export type TypeTeamValueObject = {
  referrer: `0x${string}`;
  referee: `0x${string}`[];
  refereeCount: number;
  teamObject: TypeTeamStruct[];
};

export type TypeTeamStruct = {
  teamAddress: `0x${string}`;
  levelIndex: number;
};

export const useGetUserTeam = (userAddress: `0x${string}` | undefined) => {
  const value = useContractCall({
    functionName: 'getUserTeam',
    args: [userAddress],
  });

  const valueObject: TypeTeamValueObject = {
    referrer: value ? (value[0] as `0x${string}`) : AddressZero,
    referee: value ? (value[1] as `0x${string}`[]) : [],
    refereeCount: value ? Number(value[2]) : 0,
    teamObject: value ? (value[3] as TypeTeamStruct[]) : [],
  };

  return valueObject;
};

export const useGetUserStakingReward = (
  userAddress: `0x${string}` | undefined
) => {
  const value = useContractCall({
    functionName: 'getStakingReward',
    args: [userAddress],
  });

  return value
    ? Number(formatNumberWithMaxDecimals(Number(value) / 10 ** 18, 8))
    : 0;
};

export const useGetUserPrincipalValue = (
  userAddress: `0x${string}` | undefined
) => {
  const value = useContractCall({
    functionName: 'getUserPrincipalValue',
    args: [userAddress],
  });

  return value
    ? Number(formatNumberWithMaxDecimals(Number(value) / 10 ** 18, 3))
    : 0;
};
