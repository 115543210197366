'use client';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Heading, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const Home = () => {
  return (
    <VStack w="full" h="100vh" minH="3xl" pt={20} justify="center">
      <VStack spacing={5}>
        <VStack>
          <Heading
            fontSize={['5xl', '6xl', '7xl']}
            fontWeight={900}
            color="orange.400"
          >
            BTCin Stakings
          </Heading>
          <Heading
            fontWeight={500}
            px={5}
            maxW={500}
            textAlign="center"
            size="md"
          >
            A community centric{' '}
            <Box as="span" color="twitter.500">
              #
            </Box>
            decentralized staking & referral reward distribution system.
          </Heading>
        </VStack>
        <Button
          w={300}
          h={14}
          borderRadius="xl"
          size="lg"
          variant="outline"
          colorScheme="orange"
          color="orange.400"
          _hover={{
            color: 'orange.500',
          }}
          rightIcon={<ChevronRightIcon />}
          as={Link}
          to="/staking"
        >
          Stake Now
        </Button>
      </VStack>
    </VStack>
  );
};
