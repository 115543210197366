import {
  Button,
  Divider,
  HStack,
  Heading,
  Input,
  NumberInput,
  NumberInputField,
  Spacer,
  Tag,
  Text,
  Toast,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useAccount,
  useBalance,
  useContractRead,
  useContractWrite,
  useNetwork,
  useWaitForTransaction,
} from 'wagmi';
import { polygon } from 'wagmi/chains';
import { supportedNetworkInfo } from '../constants/SupportedNetworkInfo';
import { isAddressValid, weiToDecimals } from '../hooks/UtilHooks';
import { CenterComponent } from '../util/Ui';
import { isAddress, parseEther } from 'viem';
import { error } from 'console';
import { useContractFunction, useEthers } from '@usedapp/core';
import { Contract } from 'ethers';
import { useGetUserTeam } from '../hooks/StakingHooks';
import { AddressZero } from '../constants/ContractAddress';

export const StakeUi = () => {
  const toast = useToast();
  const { referrerAddress } = useParams<{
    referrerAddress: `0x${string}`;
  }>();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const userTeam = useGetUserTeam(address);
  const currentNetwork = supportedNetworkInfo[chain?.id ?? polygon?.id];
  const userBalanceToken = useBalance({
    address: address,
    token: currentNetwork?.BTCIN?.contractAddress,
  });

  const [userInputValue, setUserInputValue] = useState('');
  const [userInputReferrer, setUserInputReferrer] = useState('');

  const {
    data: userAllowanceInWei,
    isError,
    isLoading,
  } = useContractRead({
    address: currentNetwork?.BTCIN?.contractAddress,
    abi: currentNetwork?.BTCIN?.contractABI,
    functionName: 'allowance',
    args: [address, currentNetwork?.stakingContractAddress],
    watch: true
  });

  const userAllowanceInDecimals = weiToDecimals(
    Number(userAllowanceInWei ?? 0),
    currentNetwork?.BTCIN?.decimals
  );

  const errors = {
    insufficientBalance: userInputValue
      ? Number(userInputValue) > Number(userBalanceToken?.data?.formatted ?? 0)
        ? true
        : false
      : false,
    insufficientAllowance: userInputValue
      ? userAllowanceInDecimals < Number(userInputValue ?? 0)
        ? true
        : false
      : false,

    invalidReferrer:
      referrerAddress && !isAddressValid(referrerAddress)
        ? true
        : userInputReferrer.length > 0 && !isAddressValid(userInputReferrer)
        ? true
        : false,
  };

  console.log('User Allowance', userAllowanceInDecimals);

  const {
    data: sendTransaction,
    isSuccess: isSuccessSendTransaction,
    writeAsync: writeAsyncSendTransaction,
    write: writeSendTransaction,
    status: statusSendTransaction,
    error: errorSendTransaction,
  } = useContractWrite({
    address: currentNetwork?.stakingContractAddress,
    abi: currentNetwork?.stakingContractInterface,
    functionName: 'stake',
    args: [
      userTeam?.referrer !== AddressZero
        ? userTeam?.referrer
        : referrerAddress ?? userInputReferrer,
      parseEther(userInputValue),
    ],
  });

  const {
    data: approveTransaction,
    isSuccess: isSuccessApproveTransaction,
    writeAsync: writeAsyncApproveTransaction,
    status: statusApproveTransaction,
  } = useContractWrite({
    address: currentNetwork?.BTCIN?.contractAddress,
    abi: currentNetwork?.BTCIN?.contractABI,
    functionName: 'approve',
    args: [currentNetwork?.stakingContractAddress, parseEther(userInputValue)],
  });

  const handleSendTransaction = async () => {
    try {} catch (error: any) {}
    await writeAsyncSendTransaction?.();
  };

  const handleApproveTransaction = async () => {
    await writeAsyncApproveTransaction();
  };

  return (
    <CenterComponent>
      <VStack w={[275, 300]} spacing={5}>
        <HStack>
          <Heading size="lg" fontWeight={500}>
            Stake
          </Heading>
        </HStack>
        <Divider />

        {userTeam?.referrer === AddressZero && (
          <>
            <HStack w="full">
              <Text>Enter Referrer Address</Text>
              <Spacer />
            </HStack>
            <Input
              h={20}
              borderRadius="3xl"
              w="full"
              placeholder="Please enter referrer address"
              onChange={(e) => setUserInputReferrer(e.target.value)}
              value={referrerAddress ?? userInputReferrer}
              isDisabled={referrerAddress ? true : false}
            ></Input>
          </>
        )}

        <HStack w="full">
          <Text>Enter amount</Text>
          <Spacer />
        </HStack>
        <NumberInput w="full" onChange={(e) => setUserInputValue(e)}>
          <NumberInputField
            h={20}
            borderRadius="3xl"
            fontSize="3xl"
            fontWeight="hairline"
            fontStyle="italic"
            placeholder="0.0"
          ></NumberInputField>
        </NumberInput>
        <VStack>
          <Heading size="md">You have</Heading>
          <Tag size="lg" borderRadius="xl" colorScheme="orange">
            <Heading size="md">
              {Number(userBalanceToken?.data?.formatted ?? 0).toFixed(2)} BTCin
            </Heading>
          </Tag>
        </VStack>
        <Button
          w="full"
          h={20}
          borderRadius="3xl"
          colorScheme="orange"
          bg="orange.400"
          _hover={{
            bg: 'orange.500',
          }}
          fontSize="lg"
          onClick={() =>
            errors.insufficientAllowance
              ? handleApproveTransaction()
              : handleSendTransaction()
          }
          isDisabled={userInputValue.length === 0 || errors.insufficientBalance}
        >
          {userInputValue
            ? errors.insufficientAllowance
              ? 'Approve'
              : 'Confirm Transaction'
            : 'Stake'}
        </Button>
      </VStack>
    </CenterComponent>
  );
};
