import { VStack } from '@chakra-ui/react'
import React from 'react'
import { StakeUi } from '../../components/StakeUi'

export const Stake = () => {
  return (
    <VStack w="full" minH={800} h="100vh" justify="center">
      <StakeUi/>
    </VStack>
  )
}
