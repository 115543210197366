import { BSCTestnet, Config, DAppProvider, useEthers } from '@usedapp/core';
import { WalletConnectV2Connector } from '@usedapp/wallet-connect-v2-connector';
import { ReactNode } from 'react';
import {
  Chain,
  Connector,
  ConnectorEvent,
  ConnectorUpdateData,
} from '@usedapp/core';
import { useAccount, useNetwork } from 'wagmi';



const walletConnectProjectId = 'e9244bd7e1f589cfa321acc66468598a';

if (!walletConnectProjectId) {
  throw new Error('WalletConnect Project id is undefined');
}

const config: Config = {
  readOnlyUrls: {
    [BSCTestnet.chainId]: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  },

  connectors: {
    walletConnectV2: new WalletConnectV2Connector({
      projectId: walletConnectProjectId,
      chains: [BSCTestnet],
      rpcMap: {
        [BSCTestnet.chainId]: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
      },
      checkGnosisSafe: true,
    }),
  },
};

export default function ProviderDApp({ children }: { children: ReactNode }) {
  const update = new ConnectorEvent<ConnectorUpdateData>();
  const { address } = useAccount();
  console.log("address on provider", address)
  const { chain } = useNetwork();
  update.emit({ chainId: chain?.id!, accounts: [`${address}`] });
  return <DAppProvider config={config}>{children}</DAppProvider>;
}
