import { Flex, VStack } from '@chakra-ui/react';
import { Nav } from './components/Nav/Nav';
import Footer from './components/Footer';
import { Outlet } from 'react-router-dom';
import { UnderMaintainance } from './components/UnderMaintainance';

export const App = () => (
  <Flex w="full" direction="column">
    {/* <Nav></Nav>
    <Outlet></Outlet>
    <Footer></Footer> */}
    <UnderMaintainance/>
  </Flex>
);
