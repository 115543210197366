import { createHashRouter } from 'react-router-dom';
import { App } from '../App';
import { Home } from '../pages/home';
import User from '../pages/user/User';
import Dashboard from '../pages/user/dashboard/Dashboard';
import { Staking } from '../pages/user/staking/Staking';
import Team from '../pages/user/team/Team';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import { Stake } from '../pages/stake/Stake';
import { TestPage } from '../pages/test/TestPage';

export const RoutesConfig = createHashRouter([
  {
    
    path: '/',
    element: <App></App>,
    children: [
      {
        index: true,
        element: <Home></Home>,
      },
      {
        path: 'staking',
        element: (
          <ProtectedRoute>
            <Stake></Stake>
          </ProtectedRoute>
        ),
      },
      {
        path: 'staking/:referrerAddress',
        element: (
          <ProtectedRoute>
            <Stake></Stake>
          </ProtectedRoute>
        ),
      },
      {
        path: '/user',
        element: (
          <ProtectedRoute>
            <User></User>
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <Dashboard></Dashboard>,
          },
          {
            path: 'dashboard/:userAddress',
            element: <Dashboard></Dashboard>,
          },
          {
            path: 'dashboard',
            element: <Dashboard></Dashboard>,
          },
          {
            path: 'team',
            element: <Team></Team>,
          },
          {
            path: 'team/:userAddress',
            element: <Team></Team>,
          },
          {
            path: 'staking',
            element: <Staking></Staking>,
          },
          {
            path: 'staking/:userAddress',
            element: <Staking></Staking>,
          },
        ],
      },
    ],
  },
  {
    path: "test",
    element: <TestPage/>
  }
]);
