/* eslint-disable no-useless-computed-key */
import { Chain, bscTestnet, polygon } from 'wagmi/chains';
import { BTCin, BUSD, StakingContractObject, USDT } from './ContractAddress';

export const projectName = 'MarsNext';
export const tagLine =
  'A reward centric decentralized protocol only made for community.';
export const AddressZero: `0x${string}` =
  '0x0000000000000000000000000000000000000000';
export const AddressDead: `0x${string}` =
  '0x000000000000000000000000000000000000dEaD';

export const supportedCurrencyIcons = [
  '/token-icons/usdt.svg',
  '/token-icons/busd.svg',
];

export const referrerLink = (userAddress: `0x${string}`) => {
  return `https://btcinarmy.com/registration/${userAddress}`;
};

export interface SupportedTokenInterface {
  contractAddress: `0x${string}`;
  contractABI: any; // Replace `any` with the actual ABI type
  name: string;
  symbol: string;
  decimals: number;
  logo: string; // Assuming `USDTLogoSVG` and `BUSDLogoSVG` are strings representing the SVGs
}

export interface CurrentNetworkInfo {
  stakingContractAddress?: `0x${string}`;
  stakingContractInterface?: any;
  ['USDT']: SupportedTokenInterface;
  ['BTCIN']: SupportedTokenInterface;
  native: Chain; // Replace `any` with the type representing the `polygon` object
  logo: string;
}

export interface SupportedNetworkInfo {
  [key: number]: CurrentNetworkInfo;
}

export const supportedNetworkInfo: SupportedNetworkInfo = {
  [polygon.id]: {
    stakingContractAddress: StakingContractObject.polygonAddress,
    stakingContractInterface: StakingContractObject.abi,
    ['USDT']: {
      contractAddress: USDT.polygonAddress,
      contractABI: USDT.abi,
      name: 'USDT',
      symbol: 'USDT',
      decimals: 6,
      logo: '/token-icons/usdt.svg',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['BTCIN']: {
      contractAddress: BTCin.polygonAddress,
      contractABI: BUSD.abi,
      name: 'BUSD',
      symbol: 'BUSD',
      decimals: 18,
      logo: '/tokenLogoSmall.svg',
    },
    native: polygon,
    logo: '/chainIcons/polygonChainLogo.svg',
  },
  [bscTestnet.id]: {
    stakingContractAddress: StakingContractObject.bscTesntnetAddress,
    stakingContractInterface: StakingContractObject.abi,
    // eslint-disable-next-line no-useless-computed-key
    ['USDT']: {
      contractAddress: USDT.bscTesntnetAddress,
      contractABI: USDT.abi,
      name: 'USDT',
      symbol: 'USDT',
      decimals: 18,
      logo: '/token-icons/usdt.svg',
    },
    ['BTCIN']: {
      contractAddress: BTCin.bscTesntnetAddress,
      contractABI: BUSD.abi,
      name: 'BUSD',
      symbol: 'BUSD',
      decimals: 18,
      logo: '/tokenLogoSmall.svg',
    },
    native: bscTestnet,
    logo: '/chainIcons/bscSmartChainLogo.svg',
  },
};
