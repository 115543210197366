import { Heading, VStack } from '@chakra-ui/react';
import { RiUser6Fill } from 'react-icons/ri';
import { CardContainer } from '../../../components/CardContainer';
import { useGetUserRewards } from '../../../hooks/StakingHooks';

export const UserCard = ({ userAddress }: { userAddress: `0x${string}` }) => {
  const userRewards = useGetUserRewards(userAddress);
  return (
    <CardContainer heading="Hey Welcome!" icon={RiUser6Fill}>
      <Heading textAlign="center">You have earned</Heading>
      <VStack>
        <Heading fontSize="5xl" color="twitter.500">
          {userRewards?.referralReward}
        </Heading>
        <Heading>BTCin</Heading>
      </VStack>

      {/* <Divider></Divider> */}
    </CardContainer>
  );
};
