import { Heading, Icon, Text, VStack } from '@chakra-ui/react';
import { FcBrokenLink } from 'react-icons/fc';
import { Counter } from './Counter';

export const UnderMaintainance = () => {
  return (
    <VStack w="full" minH="100vh" justify="center">
      <Icon as={FcBrokenLink} boxSize={200}></Icon>
      {/* <Counter timeinseconds={1729231175} /> */}
      <Heading textAlign="center" color="red" fontSize="5xl" fontWeight="bold">
        Website Under Maintenance.
      </Heading>
      <Text textAlign="center">
      Our servers has been crashed, we are recovering data as soon as possible, don't worry, your assets are safe.  We are arranging to transfer your assets to your wallet as soon as possible and we restart staking.
      </Text>
    </VStack>
  );
};
