import { Button } from '@chakra-ui/react';
import { shortenAddress, useEthers } from '@usedapp/core';
import React from 'react';

export const TestPage = () => {
  const { activateBrowserWallet, account, deactivate } = useEthers();
  return <Button onClick={() => !account ? activateBrowserWallet({
    type: "walletConnectV2"
  }) : deactivate()}>{account ? shortenAddress(account) : 'Connect'}</Button>;
};
